<template>
    <v-row no-gutters class="ma-0 pa-0" justify="center" style="height: 100%;">
        <v-col cols="12" class="ma-0 pa-0" style="height: 100%;">
        <v-row justify="center" class="py-5">
             <h1 class="text-h4 font-weight-light">Contact Us</h1>
        </v-row>
        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <v-card elevation="12" class="px-10">
                    <v-card-text class="text-h6 text-center">Email</v-card-text>
                    <v-divider class="mb-5 mx-5"></v-divider>
                    <v-row justify="center" class="pt-5 px-5">
                        <v-select :items="mailtoChoices" v-model="selected" return-object label="Select a topic" outlined class="mx-2"></v-select>
                    </v-row>
                    <v-card-text class="text-body-1 text-center">
                        <p>Contact link:</p>
                        <p><a :href="mailto">{{selected.email}}</a></p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        </v-col>
    </v-row>
</template>
<script>
export default {
    data() {
        return {
            selected: { text: '', email: 'hello@cryptium.com' },
            mailtoChoices: [
                { text: 'Learn more about Liberty.io', value: 'learn-more', email: 'hello@cryptium.com' },
                { text: 'How to sign up', value: 'signup', email: 'support@cryptium.com' },
                { text: 'Customer support', value: 'support', email: 'support@cryptium.com' },
                { text: 'Security', value: 'security', email: 'security@cryptium.com' },
                { text: 'Any other topic', value: 'other', email: 'someone@cryptium.com' },
            ],
        };
    },
    computed: {
        mailto() {
            if (this.selected.value === 'other') {
                return `mailto:${this.selected.email}`;
            }
            return `mailto:${this.selected.email}?subject=${encodeURIComponent(this.selected.text)}`;
        },
    },
    created() {
        if (this.$route.query.topic) {
            const found = this.mailtoChoices.find((item) => item.value === this.$route.query.topic);
            if (found) {
                this.selected = found;
            }
        }
    },
};
</script>
